import * as React from "react";
import styled from "styled-components";
import { Header, TechnologiesUsed, Footer, Image } from "../components";

const SudsCreativeProjectStyles = styled.div`
  padding: 17px;
  .project-description {
    width: 66.6667%;
    margin: 0 auto 80px;
  }
  p,
  ul {
    color: ${(props) => props.theme.text.paragraph};
  }
  h1 {
    text-align: center;
  }
  .clients-words-container {
    margin-top: 60px;
  }
  .project-description p {
    text-align: center;
    color: ${(props) => props.theme.text.paragraph};
  }
  .about-the-project-container {
    width: 66.667%;
    margin: auto;
  }

  .company-img-container {
    display: flex;
    justify-content: center;
    padding: 17px 0;
    margin-top: 80px;
  }
  .company-img-container img {
    width: 100%;
  }
  .client-description-quote {
    border-left: 1px solid #ddd;
    padding-left: 1.618em;
    margin: 0;
  }
  .project-summary-container {
    margin-top: 90px;
  }
  .SudsCreative-img-container {
    display: flex;
    justify-content: center;
    padding: 30px;
  }
`;

const gridItems = [
  { link: "", imageName: "react", text: "react" },
  { link: "", imageName: "awsImage", text: "aws" },
  { link: "", imageName: "djangoImage", text: "django" },
  { link: "", imageName: "python", text: "python" },
  { link: "", imageName: "laravelImage", text: "github" },
  { link: "", imageName: "digitalOceanImage", text: "digital ocean" },
  { link: "", imageName: "dockerImage", text: "docker" },
  { link: "", imageName: "gitlabImage", text: "gitlab" },
  { link: "", imageName: "gatsbyImage", text: "gatsby" },
];

const bulletItems = [
  { bullet: "React", subBullets: [] },
  { bullet: "Python/Django", subBullets: [] },
  {
    bullet: "Relational databases - Postgres, MySQL, Firebird",
    subBullets: [],
  },
  { bullet: "NodeJS", subBullets: [] },
  { bullet: "Docker", subBullets: [] },
  {
    bullet: "Amazon Web Services (Lambda, API Gateway, RDS)",
    subBullets: [],
  },
  { bullet: "Digital Ocean", subBullets: [] },
  { bullet: "Gatsby", subBullets: [] },
  { bullet: "Gitlab (for git hosting and CI/CD)", subBullets: [] },
  { bullet: "PHP/Laravel", subBullets: [] },
  {
    bullet:
      "API integrations (Twilio, Mailgun, Active Campaign, and many more)",
    subBullets: [],
  },
];

const SudsCreativePage = () => (
  <main>
    <Header />
    <SudsCreativeProjectStyles>
      <div className="project-description">
        <h1>Suds Creative</h1>
        <p>
          We have been helping{" "}
          <a href="https://sudscreative.com/">
            <span>Suds Creative</span>
          </a>{" "}
          by filling the role of tech strategy and engineering team leadership,
          along with architecture and development on all aspects of their web
          tech stack.
        </p>
        <p>
          We have developed tools to use internally, client facing products,
          work directly with clients and internal stakeholders to build tech
          behind the greatest car wash marketing company ever seen.
        </p>
      </div>
      <TechnologiesUsed
        gridItems={gridItems}
        bulletItems={bulletItems}
        columnCount={3}
      />
      <div className="about-the-project-container">
        <div className="company-img-container">
          <a href="https://sudscreative.com/">
            <Image imageName="suds-logo" alt="Suds" />
          </a>
        </div>
        <h3>About the Client (Suds Creative)</h3>
        <p>
          Suds Creative has been innovating in the car wash marketing space for
          years. They are leaders in using data and design together to help car
          washes run smoothly and create a great experience for their customers.
        </p>
        <div className="client-description-quote">
          <p>
            “Suds Creative has been laser focused on their industry and crushing
            it in a way that nobody can match. The combination of tech solutions
            with such an in-depth understanding of their customers is their
            recipe for great software solutions that solves real problems.” —
            Jeremy Zerr, Founder, Zerrtech
          </p>
        </div>
        <div className="clients-words-container">
          <h3>In the Client&apos;s Words</h3>
          <div className="client-description-quote">
            <p>
              “Zerrtech has been a development partner of Suds from the
              beginning and is an invaluable resource to our team and our
              clients. Uber-professional, they communicate well, and work
              seamlessly with our in-house engineering team. I would recommend
              Zerrtech to any company large or small that wants their project
              done correctly, and on time.” — Jason Baumgartner, Co-founder and
              President,{" "}
              <a href="https://www.linkedin.com/in/jasonbaumgartner/">
                <span>LinkedIn</span>
              </a>{" "}
            </p>
          </div>
        </div>
        <div className="project-summary-container">
          <h3>Project Roles</h3>
          <p>
            Our roles for Suds Creative include:
            <ul>
              <li>
                Created automated data analysis web application to deliver live
                KPIs to car wash owners with charts powered by D3.js in React,
                along with text/email reports. Backend REST API powered by
                Python + Django and a Postgres DB, delivered via Digital Ocean
                cloud.
              </li>
              <li>
                Designed many automated processes to analyze data, integrate
                with marketing and business tools to keep data and decisions
                flowing.
              </li>
              <li>
                Providing strategy around development practices and helping to
                lead internal engineering teams.
              </li>
              <li>
                Creating Amazon Web Services cloud architecture to host APIs in
                Lambda + API Gateway.
              </li>
              <li>
                Created web application to enable a team of sales people to
                generate 50+ page custom PDF reports based on criteria used to
                evaluate new car wash locations.
              </li>
              <li>
                Working with designs provided by Suds Creative internal design
                team to implement responsive UI across web sites and emails.
              </li>
            </ul>
          </p>
        </div>
      </div>
    </SudsCreativeProjectStyles>
    <Footer />
  </main>
);

export default SudsCreativePage;
